<template>
    <v-container>
        <DocumentosOficialCumplimiento :id-oficial-cumplimiento="idOficialCumplimiento" mostrar-boton-navegacion />
    </v-container>
</template>
<script>
import DocumentosOficialCumplimiento from './components/verificacion/DocumentosOficialCumplimiento.vue';

export default {
    components: { DocumentosOficialCumplimiento },
    data: () => ({
        idOficialCumplimiento: null,
    }),
    created() {
        this.idOficialCumplimiento = this.$route.params.id_oficial;
    },
}
</script>